@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes card-appear-animation {
  0% {
    transform: scale(0.01);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes train-segment-glow {
  50% {
    @apply bg-white;
  }
}
.mantine-ScrollArea-viewport.no-display-table > div {
  display: block !important;
}
.player-card.player-disconnected {
  @apply bg-gray-400 text-gray-700;
}
.player-card-black {
  @apply bg-gray-700 text-gray-100 border-gray-900;
}
.player-card-black .player-status-connected {
  @apply text-gray-300;
}
.player-card-blue {
  @apply bg-blue-300 border-blue-700;
}
.player-card-blue .player-status-connected {
  @apply text-blue-900;
}
.player-card-green {
  @apply bg-green-300 border-green-700;
}
.player-card-green .player-status-connected {
  @apply text-green-900;
}
.player-card-red {
  @apply bg-red-400 border-red-700;
}
.player-card-red .player-status-connected {
  @apply text-red-900;
}
.player-card-yellow {
  @apply bg-yellow-300 border-yellow-800 text-black;
}
.player-card-yellow .player-status-connected {
  @apply text-yellow-900;
}

.train-segment-blue {
  @apply bg-cyan-300 border-cyan-500;
}
.train-segment-red {
  @apply bg-red-500 border-red-700;
}
.train-segment-pink {
  @apply bg-grape-400 border-grape-600;
}
.train-segment-white {
  @apply bg-gray-200 border-gray-400;
}
.train-segment-gray {
  @apply bg-gray-500 border-gray-600;
}
.train-segment-black {
  @apply bg-gray-800 border-gray-700;
}
.train-segment-green {
  @apply bg-green-400 border-green-700;
}
.train-segment-rainbow {
  @apply bg-gradient-to-r from-yellow-400 to-grape-400 border-pink-600;
  @apply group-hover:from-pink-500 group-hover:to-yellow-400;
}
.train-segment-orange {
  @apply bg-orange-400 border-orange-600;
}
.train-segment-yellow {
  @apply bg-yellow-300 border-yellow-600;
}

.shown-card-blue {
  @apply bg-cyan-400 border-cyan-600 text-white disabled:text-gray-300;
  @apply hover:bg-cyan-300 hover:text-black;
  @apply group-hover:bg-cyan-300 group-hover:text-black;
}
.shown-card-red {
  @apply bg-red-600 border-red-800 text-white disabled:text-gray-300;
  @apply hover:bg-red-500;
  @apply group-hover:bg-red-500;
}
.shown-card-pink {
  @apply bg-grape-400 border-grape-600 text-white disabled:text-gray-300;
  @apply hover:bg-grape-300 hover:text-black;
  @apply group-hover:bg-grape-300 group-hover:text-black;
}
.shown-card-white {
  @apply bg-gray-100 border-gray-700;
  @apply hover:bg-gray-50;
  @apply group-hover:bg-gray-50;
}
.shown-card-black {
  @apply bg-gray-800 border-gray-500 text-white disabled:text-gray-300;
  @apply hover:bg-gray-700;
  @apply group-hover:bg-gray-700;
}
.shown-card-green {
  @apply bg-green-500 border-green-700 text-white disabled:text-gray-300;
  @apply hover:bg-green-300 hover:text-black;
  @apply group-hover:bg-green-300 group-hover:text-black;
}
.shown-card-rainbow {
  @apply relative overflow-hidden bg-opacity-0 bg-white text-white;
  @apply disabled:text-gray-300;
}
.shown-card-rainbow:hover::before {
  @apply rotate-90;
}
.shown-card-rainbow::before {
  @apply absolute;
  @apply transition-all;
  @apply duration-300;
  @apply group-hover:rotate-90;
  @apply rotate-45;
  content: '';
  width: 200%;
  height: 180%;
  top: -50%;
  left: -50%;
  z-index: -1;

  background: linear-gradient(
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
}
.shown-card-orange {
  @apply bg-orange-500 border-orange-600 text-white disabled:text-gray-300;
  @apply hover:bg-orange-300 hover:text-black;
  @apply group-hover:bg-orange-300 group-hover:text-black;
}
.shown-card-yellow {
  @apply bg-yellow-300 border-yellow-600;
  @apply hover:bg-yellow-200;
  @apply group-hover:bg-yellow-200;
}

.train-player-red {
  @apply bg-red-700;
}
.train-player-blue {
  @apply bg-blue-600;
}
.train-player-green {
  @apply bg-green-700;
}
.train-player-yellow {
  @apply bg-yellow-600;
}
.train-player-black {
  @apply bg-black;
}

.status-player-turn {
  @apply bg-green-200;
  animation: status-player-turn-animation 4s infinite;
}
@keyframes status-player-turn-animation {
  70% {
    @apply bg-green-600;
  }
  90% {
    @apply bg-yellow-400;
  }
}

.deck-cards-3 {
  box-shadow: -3px 3px theme(backgroundColor.yellow.400),
    -6px 6px theme(backgroundColor.yellow.500),
    -9px 9px theme(backgroundColor.yellow.600);
}

.deck-cards-2 {
  box-shadow: -3px 3px theme(backgroundColor.yellow.400),
    -6px 6px theme(backgroundColor.yellow.500);
}

.deck-cards-1 {
  box-shadow: -3px 3px theme(backgroundColor.yellow.400);
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
}
